import * as Sentry from '@sentry/react';
import { config } from '../config/config';

const isSentryDisabled = config.environment === 'local' || !config.sentry.dsn;

/** logs an exception into sentry */
export const captureException = (error: unknown) => {
    if (isSentryDisabled) return;
    Sentry.captureException(error);
};

/** initialises sentry setup */
export function initSentry() {
    if (isSentryDisabled) return;

    Sentry.init({
        dsn: config.sentry.dsn,
        environment: config.environment,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.05,
    });

    const [tenant] = window.location.hostname.split('.');
    Sentry.setTag('tenant', tenant);
}
